import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { FaBook, FaDiscord, FaTwitter, FaTshirt } from 'react-icons/fa';
import { AiOutlineHome, AiOutlineConsoleSql } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';

const navLinks = [
  {
    name: 'Website',
    url: 'https://uselessbrick.com',
    icon: <AiOutlineHome />,
  },
  {
    name: 'Explorer',
    url: 'https://explorer.uselessbrick.com',
    icon: <BiSearch />,
  },
  {
    name: 'Console',
    url: 'https://console.uselessbrick.com',
    icon: <AiOutlineConsoleSql />,
  },
  {
    name: 'Discord',
    url: 'https://discord.gg/EtHYAdZckP',
    icon: <FaDiscord />,
  },
  {
    name: 'X',
    url: 'https://x.com/useless_brick',
    icon: <FaTwitter />,
  },
  {
    name: 'Docs',
    url: 'https://docs.uselessbrick.com',
    icon: <FaBook />,
  },
  {
    name: 'Merch',
    url: 'https://www.bonfire.com/store/useless-brick/',
    icon: <FaTshirt />,
  },
];

function Header() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.background.default,
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Divider />
      <List>
        {navLinks.map((link) => (
          <ListItem
            button
            component="a"
            href={link.url}
            key={link.name}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              {link.icon}
            </ListItemIcon>
            <ListItemText
              primary={link.name}
              primaryTypographyProps={{ color: theme.palette.text.primary }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      {/* Notification Banner */}
      <Alert
        severity="info"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#e3f2fd',
          color: '#0d47a1',
          borderBottom: '1px solid #0d47a1',
        }}
      >
        Currently running on Solana devnet. Check it on{' '}
        <a
          href="https://solscan.io/token/Fg6hP84ZMeALgn2vdHg1ft4xkjKDr3XTyT8Y3GFkgD6D?cluster=devnet"
          target="_self" // Ensures it opens in the same tab
          rel="noopener noreferrer"
          style={{ marginLeft: '5px', color: '#0d47a1', fontWeight: 'bold' }}
        >
          Solscan
        </a>
        !
      </Alert>

      <AppBar position="static" sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ color: 'white' }}>
            Useless Brick Explorer
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {isSmallScreen ? (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawerList()}
              </Drawer>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {navLinks.map((link) => (
                <Button
                  key={link.name}
                  href={link.url}
                  sx={{
                    color: 'white',
                    textTransform: 'none',
                    marginLeft: 2,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '6px 10px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                  aria-label={`Visit ${link.name}`}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {link.icon}
                  </Box>
                  <Typography variant="body1" sx={{ marginLeft: 0.5 }}>
                    {link.name}
                  </Typography>
                </Button>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;


// src/pages/Explorer.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import ChartJS from 'chart.js/auto';
import { format, parseISO, eachDayOfInterval } from 'date-fns';

// Adjust the import paths according to your project structure
import Header from '../components/Header'; // Adjust the path if necessary
import theme from '/root/explorer/src/pages/theme'; // Adjust the path if necessary

function StatisticCard({ title, value, isLoading, error }) {
  return (
    <Card elevation={3} sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Typography variant="h4">{value}</Typography>
        )}
      </CardContent>
    </Card>
  );
}

function TotalRewards() {
  const [totalRewards, setTotalRewards] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTotalRewards = async () => {
      try {
        const response = await fetch('/api/statistics/total_rewards', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTotalRewards(data.total_rewards);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch total rewards');
        }
      } catch (err) {
        setError('Error fetching total rewards: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTotalRewards();
  }, []);

  // Function to format the total rewards number
  const formatNumber = (number) => {
    if (typeof number !== 'number') return '0';
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);
  };

  return (
    <StatisticCard
      title="Total Miner Rewards"
      value={formatNumber(totalRewards)}
      isLoading={isLoading}
      error={error}
    />
  );
}

function TotalBurnedTokens() {
  const [totalBurned, setTotalBurned] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTotalBurned = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/total_burned`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          const totalBurnedValue = parseFloat(data.total_burned);
          if (!isNaN(totalBurnedValue)) {
            setTotalBurned(totalBurnedValue);
            setError('');
          } else {
            setError('Invalid total burned value received from the server.');
          }
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch total burned tokens');
        }
      } catch (err) {
        setError('Error fetching total burned tokens: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTotalBurned();
  }, []);

  return (
    <StatisticCard
      title="Total Tokens Burned"
      value={
        totalBurned !== null
          ? totalBurned.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : 'N/A'
      }
      isLoading={isLoading}
      error={error}
    />
  );
}

function TotalRounds() {
  const [totalRounds, setTotalRounds] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTotalRounds = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/total_rounds`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTotalRounds(data.total_rounds);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch total rounds');
        }
      } catch (err) {
        setError('Error fetching total rounds: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTotalRounds();
  }, []);

  return (
    <StatisticCard
      title="Total Rounds Conducted"
      value={totalRounds !== null ? totalRounds.toLocaleString() : 'N/A'}
      isLoading={isLoading}
      error={error}
    />
  );
}

function RoundsBurned() {
  const [roundsBurned, setRoundsBurned] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRoundsBurned = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/rounds_burned`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRoundsBurned(data.rounds_burned);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch rounds burned');
        }
      } catch (err) {
        setError('Error fetching rounds burned: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRoundsBurned();
  }, []);

  return (
    <StatisticCard
      title="Number of Rounds Burned"
      value={roundsBurned !== null ? roundsBurned.toLocaleString() : 'N/A'}
      isLoading={isLoading}
      error={error}
    />
  );
}

function BurnRate() {
  const [burnRate, setBurnRate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBurnRate = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/burn_rate`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.burn_rate !== undefined) {
            setBurnRate(data.burn_rate);
          } else {
            throw new Error('Burn rate data is missing');
          }
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch burn rate');
        }
      } catch (err) {
        setError('Error fetching burn rate: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBurnRate();
  }, []);

  return (
    <StatisticCard
      title="Burn Rate"
      value={burnRate !== null ? `${burnRate}%` : 'No burn rate data available'}
      isLoading={isLoading}
      error={error}
    />
  );
}

function TotalMiners() {
  const [totalMiners, setTotalMiners] = useState(null);
  const [onlineToday, setOnlineToday] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMinersStats = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/miners/stats`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTotalMiners(data.total_miners);
          setOnlineToday(data.online_today);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch miners statistics');
        }
      } catch (err) {
        setError('Error fetching miners statistics: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMinersStats();
  }, []);

  // Function to format the miner statistics
  const formatMinerStats = () => {
    if (onlineToday === null || totalMiners === null) return 'N/A';
    return `${onlineToday.toLocaleString()} / ${totalMiners.toLocaleString()}`;
  };

  return (
    <StatisticCard
      title="Miners Today / All-time"
      value={isLoading || error ? undefined : formatMinerStats()}
      isLoading={isLoading}
      error={error}
    />
  );
}

function RewardsOverTime() {
  const [rewardsOverTime, setRewardsOverTime] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [interval, setIntervalState] = useState('daily'); // 'daily', 'weekly', 'monthly'

  useEffect(() => {
    const fetchRewardsOverTime = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(
          `${API_BASE_URL}/api/statistics/rewards_over_time?interval=${interval}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setRewardsOverTime(data.rewards_over_time);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch rewards over time');
        }
      } catch (err) {
        setError('Error fetching rewards over time: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRewardsOverTime();
  }, [interval]);

  if (isLoading) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  // Prepare data for Chart.js with modern styling
  const labels = rewardsOverTime.map((item) => format(parseISO(item.period), 'MM/dd'));

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Rewards',
        data: rewardsOverTime.map((item) => item.total_rewards),
        fill: true,
        backgroundColor: 'rgba(153,102,255,0.2)', // Same color as MinerGrowthOverTime
        borderColor: 'rgba(153,102,255,1)',
        pointBackgroundColor: 'rgba(153,102,255,1)',
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Total Rewards',
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Rewards Over Time
      </Typography>
      <Line data={data} options={options} />
    </Paper>
  );
}

function MinerGrowthOverTime() {
  const [minerGrowth, setMinerGrowth] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMinerGrowth = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/miner_growth_daily`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMinerGrowth(data.miner_growth_daily);
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch miner growth data');
        }
      } catch (err) {
        setError('Error fetching miner growth data: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMinerGrowth();
  }, []);

  if (isLoading) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  // Function to generate an array of dates from start to today
  const generateDateRange = (startDate, endDate) => {
    return eachDayOfInterval({
      start: parseISO(startDate),
      end: parseISO(endDate),
    });
  };

  // Find the first date from the data
  const firstDate = minerGrowth.length > 0 ? minerGrowth[0].date : null;
  const today = format(new Date(), 'yyyy-MM-dd');

  if (!firstDate) {
    // No miners have joined yet
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          Miner Growth Over Time (Daily)
        </Typography>
        <Alert severity="info">No miners have joined yet.</Alert>
      </Paper>
    );
  }

  // Generate complete date range from first miner to today
  const dateRange = generateDateRange(firstDate, today);

  // Create a map for quick lookup of new miners per date with 'YYYY-MM-DD' keys
  const minerMap = {};
  minerGrowth.forEach((item) => {
    const dateKey = format(parseISO(item.date), 'yyyy-MM-dd');
    minerMap[dateKey] = item.new_miners;
  });

  // Initialize cumulative total
  let cumulativeTotal = 0;

  // Prepare labels and cumulative data
  const labels = [];
  const cumulativeData = [];

  dateRange.forEach((date) => {
    const dateString = format(date, 'yyyy-MM-dd');
    const newMiners = minerMap[dateString] || 0;
    cumulativeTotal += newMiners;
    labels.push(format(date, 'MM/dd'));
    cumulativeData.push(cumulativeTotal);
  });

  // Prepare data for Chart.js with modern styling
  const data = {
    labels,
    datasets: [
      {
        label: 'Total Miners',
        data: cumulativeData,
        fill: true,
        backgroundColor: 'rgba(153,102,255,0.2)',
        borderColor: 'rgba(153,102,255,1)',
        pointBackgroundColor: 'rgba(153,102,255,1)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Miner Growth Over Time (Daily)',
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: 'Total Miners',
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Miner Growth Over Time (Daily)
      </Typography>
      <Line data={data} options={options} />
    </Paper>
  );
}

function TopMiners() {
  const [topMiners, setTopMiners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTopMiners = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/top_miners`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (Array.isArray(data.top_miners)) {
            const formattedMiners = data.top_miners.map((miner) => ({
              ...miner,
              total_rewards: parseFloat(miner.total_rewards),
            }));

            setTopMiners(formattedMiners.slice(0, 10)); // Limit to top 10
            setError('');
          } else {
            setError('Invalid data format received from the server.');
          }
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch top miners');
        }
      } catch (err) {
        setError('Error fetching top miners: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopMiners();
  }, []);

  if (isLoading) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Top 10 Miners by Rewards
      </Typography>
      <TableContainer>
        <Table aria-label="top miners table">
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Miner ID</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Total Rewards</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topMiners.length > 0 ? (
              topMiners.map((miner, index) => (
                <TableRow key={miner.miner_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{miner.miner_id}</TableCell>
                  <TableCell>{miner.address}</TableCell>
                  <TableCell>
                    {miner.total_rewards.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No miners found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function TopBurners() {
  const [topBurners, setTopBurners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTopBurners = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/statistics/top_burners`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTopBurners(data.top_burners.slice(0, 10)); // Limit to top 10
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch top burners');
        }
      } catch (err) {
        setError('Error fetching top burners: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopBurners();
  }, []);

  if (isLoading) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Top 10 Miners by Amount Burned
      </Typography>
      <TableContainer>
        <Table aria-label="top burners table">
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Miner ID</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Total Burned Rounds</TableCell>
              <TableCell>Total Amount Burned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topBurners.map((burner, index) => (
              <TableRow key={burner.miner_id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{burner.miner_id}</TableCell>
                <TableCell>{burner.address}</TableCell>
                <TableCell>{burner.total_burned_blocks.toLocaleString()}</TableCell>
                <TableCell>
                  {parseFloat(burner.total_burned_amount).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function LatestRounds() {
  const [rounds, setRounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRounds = async () => {
      try {
        const API_BASE_URL =
          process.env.REACT_APP_API_BASE_URL || 'https://console.uselessbrick.com';

        const response = await fetch(`${API_BASE_URL}/api/rounds`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRounds(data.rounds.slice(0, 10));
          setError('');
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch rounds data');
        }
      } catch (err) {
        setError('Error fetching rounds data: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRounds();
  }, []);

  if (isLoading) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Latest 10 Rounds
      </Typography>
      <TableContainer>
        <Table aria-label="latest rounds table">
          <TableHead>
            <TableRow>
              <TableCell>Round ID</TableCell>
              <TableCell>Winner Miner ID</TableCell>
              <TableCell>Winner Address</TableCell>
              <TableCell>Reward</TableCell>
              <TableCell>Round Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rounds.map((round) => (
              <TableRow key={round.round_id}>
                <TableCell>{round.round_id}</TableCell>
                <TableCell>{round.winner_miner_id_str || 'N/A'}</TableCell>
                <TableCell>{round.winner_address || 'N/A'}</TableCell>
                <TableCell>{round.reward}</TableCell>
                <TableCell>{new Date(round.round_date).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function Explorer() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {/* Statistics Cards */}
          <Grid item xs={12} sm={6} md={4}>
            <TotalRewards />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TotalBurnedTokens />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TotalRounds />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <RoundsBurned />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BurnRate />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TotalMiners />
          </Grid>
        </Grid>

        {/* Charts */}
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <RewardsOverTime />
          </Grid>
          <Grid item xs={12} md={6}>
            <MinerGrowthOverTime />
          </Grid>
        </Grid>

        {/* Tables */}
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <TopMiners />
          </Grid>
          <Grid item xs={12}>
            <TopBurners />
          </Grid>
        </Grid>

        {/* Latest Rounds */}
        <Box sx={{ mt: 4 }}>
          <LatestRounds />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Explorer;

